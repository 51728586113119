<template>
  
  <div>
    <Header/>
    <Diapo/>
    <!--Service-->
    <v-container fluid id="Services" class="mt-10">
      <div style="position:relative;">
        <v-row>
          <div class="purpleBanner">
            <v-row>
              <v-col cols="12" lg="7">
                <div>
                  <v-row>
                    <v-col cols="12" md="7">
                      <h2 class="HomestoreTitle" >Commandez en ligne !</h2>
                    </v-col>
                    <v-col cols="12" md="5">
                      <div class="marginBtnModif"><v-btn  to="/Boutique" target="_blank" class="HomebtnStore">Boutique en ligne</v-btn></div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" lg="5"></v-col>
            </v-row>
          </div>

          <v-col cols="12" lg="6" >
            <v-row class="TextRow"  style="margin-bottom: 250px !important;">
              <v-col cols="12" md="6" class="grid-pad-home-a">
                <v-img class="serviceBox" src="../../public/Resource/ServicesBox.png" ></v-img>
                <div>
                  <h3 class="f-primary mt-10">DÉCORATION À DOMICILE</h3>
                  <h3 class="f-secondary">Pour un décor à votre image</h3>
                  <p class="mt-5">Tout comme le mobilier, les plantes et les fleurs font parties intégrante de notre environnement. Osez en incorporant de nouveaux arrangements de soie ou séchés et plantes vertes naturelles ou synthétiques de qualité supérieure. Nous offrons le service de décoration à domicile. Estimation gratuite. </p>
                  <h3 class="f-primary mt-10">DÉCORATION DE BUREAUX ET ENTRETIEN DE PLANTES</h3>
                  <p class="mt-5">Tout comme pour la maison, la présence de fleurs et de plantes dans votre milieu de travail favorise une ambiance harmonieuse, chaleureuse et stimulante.</p>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="grid-pad-home-d grid-pad-home-c">
                <div class="mt-2">
                  <p>Nos professionnels vous guideront vers les choix les plus judicieux afin de transformer un lieu de travail ordinaire en quelque chose d'extraordinaire. Informez-vous sur notre service d'entretien régulier.</p>
                  <h3 class="f-primary mt-10">MARIAGE : DÉCORATION DE SALLES ET CONCEPTION D'ARRANGEMENTS</h3>
                  <p class="mt-5">Ce demi-siècle d'existence nous a permis de servir des centaines de couples et contribuer à leur bonheur. Nos fleuristes expérimentés sont passés maîtres dans la conception d'arrangements uniques. Peu importe le thème suggéré, nous vous proposerons de quoi épater vos convives dans le respect de votre budget.</p>
                  <h3 class="f-primary mt-10">SERVICE DE LIVRAISON</h3>
                  <p class="mt-5">Vite fait, bien fait.<br>Nos fleurs et plantes se rendent à bonne destination des mains de livreurs courtois dont la satisfaction demeure leur principal souci. Notre localisation nous permet de couvrir un large rayon de 40 kilomètres assurant ainsi toute livraison dans un délai limité. </p>
                </div >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="0" lg="1"><div class="borderBox"></div></v-col>
          <v-col cols="0" lg="5"><div class="servicesIMG"></div></v-col>
        </v-row>       
      </div>

    </v-container>
    <!--Photo Section-->  
    <v-container fluid class="WaterMarkBackground">
      <div>
        <router-link to="/weddingconception"><v-img class="photographerIMG" src="../../public/Resource/photographer.jpg"></v-img></router-link>
        <div class="photographerText">
          <router-link to="/weddingconception"><h2 class="f-primary weddingText">Un bouquet parfait pour mon mariage !</h2></router-link>
          <router-link to="/weddingconception"><h3 class="f-secondary dayText">Fidèle complice de votre journée </h3></router-link>
        </div>
      </div>
    </v-container>
    <!--Conception Section--> 
    <v-container fluid class="ConceptionBanner">
      <div class="ConceptionColor">
        <router-link to="/conception"><h2 class="conceptionText">Nos conceptions</h2></router-link>
      </div>
    </v-container>
    <!--50 years Section-->
    <v-container fluid class="HomeFiftyContentSection">
      <div>
        <h2 class="HomeFiftyYearsTitle">Notre passion au<br>fil des ans</h2>
      </div>
      <div>
        <div class="HomefirstFounder">
          <h3 class="HomefirstFounderText f-secondary">Fondateur</h3>
          <v-img class="HomefirstFounderIMG" src="../../public/Resource/FiftyYears/Founder.jpg"></v-img>
        </div>
        <div class="HomesecondStore">
          <h3 class="HomesecondStoreText f-secondary">Seconde boutique</h3>
          <v-img class="HomesecondStoreIMG" src="../../public/Resource/FiftyYears/secondStore.jpg"></v-img>
        </div>
        <div class="HomesecondFounder">
          <h3 class="d-flex d-md-none HomesecondFounderText f-secondary">Fondatrice</h3>
          <v-img class="HomesecondFounderIMG" src="../../public/Resource/FiftyYears/Founder-2.jpg"></v-img>
          <h3 class="d-none d-md-flex HomesecondFounderText f-secondary">Fondatrice</h3>
        </div>
        <div class="HomefirstStore">
          <h3 class="d-flex d-md-none HomefirstStoreText f-secondary">Première boutique</h3>
          <v-img class="HomefirstStoreIMG" src="../../public/Resource/FiftyYears/firstStore.jpg"></v-img>
          <h3 class="d-none d-md-flex HomefirstStoreText f-secondary">Première boutique</h3>
        </div>
      </div>

    </v-container>
    <!--Products--> 
    <v-container fluid id="Products">
      <div style="position:relative;">
        <v-row>
          <div class="purpleBanner">
            <v-row>
              <v-col cols="12" lg="5">
              </v-col>
              <v-col cols="12" lg="7">
                <div>
                  <v-row>
                    <v-col cols="12" md="3" >
                      <div class="marginBtnModif" s><v-btn to="/Boutique" target="_blank" class="HomebtnStore2">Boutique en ligne</v-btn></div>
                    </v-col>
                    <v-col cols="12" md="8" style="float: right;">
                      <h2 class="HomestoreTitle2" >Commandez en ligne !</h2>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-col cols="0" lg="5" ><div class="productsIMG"></div></v-col>
          <v-col cols="0" lg="1"><div class="borderBox2"></div></v-col>
          <v-col cols="12" lg="6" >
            <v-row class="TextRow" style="margin-bottom: 250px !important;">
              <div class="">
                <v-col cols="12" md="6" class="grid-pad-home-b grid-pad-home-c" style="float:right;">
                  <v-img class="productBox" src="../../public/Resource/ProduitsBox.png" ></v-img>
                  <div class="">
                    <h3 class="f-primary mt-10">Paniers de fruits</h3>
                    <p class="mt-5">Laissez-nous vous concocter un superbe panier de fruits que nous agrémenterons, au goût, de chocolat, biscottes, friandises et fleurs. Un cadeau tout aussi original que délicieux.<br>Déco-cadeau</p>
                    <p class="mt-5 mb-10">Déambuler dans notre vaste espace plancher à la conquête de trouvailles inédites est un réel plaisir. Figurines, cadres, coffres, chandeliers, bijoux et accessoires comptent parmi la panoplie de produits qui trouveront place dans tout décor. Fleuriste Bergeron, bien plus que des fleurs.</p>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="grid-pad-home-a">
                  <div class="mt-2">
                    <h3 class="f-primary mt-10">Fleurs coupées et arrangements naturels</h3>
                    <p class="mt-5">Toujours fraîches, nos fleurs proviennent de la Hollande, de l'équateur, de l'Italie, de la France et, bien sûr, du Canada. Indiquez-nous ce que vous recherchez ainsi que vos goûts et votre budget et nous vous créerons un arrangement unique.</p>
                    <h3 class="f-primary mt-10">Fleurs séchées et soie</h3>
                    <p class="mt-5">À partir de vos couleurs de peinture, de papier peint ou de tissus ainsi que de vos propres photos, nos spécialistes vous proposeront des conceptions au goût du jour adaptées à votre décor.</p>
                    <h3 class="f-primary mt-10">Plantes et jardins de plantes</h3>
                    <p class="mt-5">Que ce soit à la maison ou au travail, il est prouvé que les plantes améliorent l'air ambiant tout en créant une atmosphère chaleureuse. Choisissez parmi notre large éventail de plantes vertes, fleuries ou tropicales qui transformeront votre environnement et le rendra encore plus accueillant. De plus, nous pouvons effectuer leur entretien à votre lieu de travail. Informez-vous!</p>
                  </div>
                </v-col>
              </div>
              <!--
              <div class="d-flex d-md-none">
                <v-col cols="12" class="service-pad ">
                  <v-img class="serviceBox" src="../../public/Resource/ProduitsBox.png" ></v-img>
                  <div>
                    <h3 class="f-primary mt-10">Paniers de fruits</h3>
                    <p class="mt-5">Laissez-nous vous concocter un superbe panier de fruits que nous agrémenterons, au goût, de chocolat, biscottes, friandises et fleurs. Un cadeau tout aussi original que délicieux.<br>Déco-cadeau</p>
                    <p class="mt-5">Déambuler dans notre vaste espace plancher à la conquête de trouvailles inédites est un réel plaisir. Figurines, cadres, coffres, chandeliers, bijoux et accessoires comptent parmi la panoplie de produits qui trouveront place dans tout décor. Fleuriste Bergeron, bien plus que des fleurs.</p>
                    <h3 class="f-primary mt-10">Fleurs coupées et arrangements naturels</h3>
                    <p class="mt-5">Toujours fraîches, nos fleurs proviennent de la Hollande, de l'équateur, de l'Italie, de la France et, bien sûr, du Canada. Indiquez-nous ce que vous recherchez ainsi que vos goûts et votre budget et nous vous créerons un arrangement unique.</p>
                    <h3 class="f-primary mt-10">Fleurs séchées et soie</h3>
                    <p class="mt-5">À partir de vos couleurs de peinture, de papier peint ou de tissus ainsi que de vos propres photos, nos spécialistes vous proposeront des conceptions au goût du jour adaptées à votre décor.</p>
                    <h3 class="f-primary mt-10">Plantes et jardins de plantes</h3>
                    <p class="mt-5">Que ce soit à la maison ou au travail, il est prouvé que les plantes améliorent l'air ambiant tout en créant une atmosphère chaleureuse. Choisissez parmi notre large éventail de plantes vertes, fleuries ou tropicales qui transformeront votre environnement et le rendra encore plus accueillant. De plus, nous pouvons effectuer leur entretien à votre lieu de travail. Informez-vous!</p>
                  </div >
                </v-col>
              </div>-->
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <!-- ancien code produit
    <v-container fluid id="Products">
      <div class="" style="position:relative;">
        <div class="purpleRectangle"></div>
        <v-row style="position:relative;">
          <v-col cols="0" lg="5"><div class="productsIMG"></div></v-col>
          <v-col cols="0" lg="1"><div class="borderBox2"></div></v-col>
          <v-col cols="12" lg="6" >
            <v-row class="TextRow" style="margin-bottom: 50px !important;">
              <div class="d-none d-md-flex">
                <v-col cols="12" sm="6" class="">
                  <div class="mt-2">
                    <h3 class="f-primary mt-10">Fleurs coupées et arrangements naturels</h3>
                    <p class="mt-5">Toujours fraîches, nos fleurs proviennent de la Hollande, de l'équateur, de l'Italie, de la France et, bien sûr, du Canada. Indiquez-nous ce que vous recherchez ainsi que vos goûts et votre budget et nous vous créerons un arrangement unique.</p>
                    <h3 class="f-primary mt-10">Fleurs séchées et soie</h3>
                    <p class="mt-5">À partir de vos couleurs de peinture, de papier peint ou de tissus ainsi que de vos propres photos, nos spécialistes vous proposeront des conceptions au goût du jour adaptées à votre décor.</p>
                    <h3 class="f-primary mt-10">Plantes et jardins de plantes</h3>
                    <p class="mt-5">Que ce soit à la maison ou au travail, il est prouvé que les plantes améliorent l'air ambiant tout en créant une atmosphère chaleureuse. Choisissez parmi notre large éventail de plantes vertes, fleuries ou tropicales qui transformeront votre environnement et le rendra encore plus accueillant. De plus, nous pouvons effectuer leur entretien à votre lieu de travail. Informez-vous!</p>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" class="service-pad">
                  <v-img class="serviceBox" src="../../public/Resource/ProduitsBox.png" ></v-img>
                  <div class="grid-pad">
                    <h3 class="f-primary mt-10">Paniers de fruits</h3>
                    <p class="mt-5">Laissez-nous vous concocter un superbe panier de fruits que nous agrémenterons, au goût, de chocolat, biscottes, friandises et fleurs. Un cadeau tout aussi original que délicieux.<br>Déco-cadeau</p>
                    <p class="mt-5">Déambuler dans notre vaste espace plancher à la conquête de trouvailles inédites est un réel plaisir. Figurines, cadres, coffres, chandeliers, bijoux et accessoires comptent parmi la panoplie de produits qui trouveront place dans tout décor. Fleuriste Bergeron, bien plus que des fleurs.</p>
                  </div>
                </v-col>
              </div>
              <div class="d-flex d-md-none">
                <v-col cols="12" class="service-pad ">
                  <v-img class="serviceBox" src="../../public/Resource/ProduitsBox.png" ></v-img>
                  <div>
                    <h3 class="f-primary mt-10">Paniers de fruits</h3>
                    <p class="mt-5">Laissez-nous vous concocter un superbe panier de fruits que nous agrémenterons, au goût, de chocolat, biscottes, friandises et fleurs. Un cadeau tout aussi original que délicieux.<br>Déco-cadeau</p>
                    <p class="mt-5">Déambuler dans notre vaste espace plancher à la conquête de trouvailles inédites est un réel plaisir. Figurines, cadres, coffres, chandeliers, bijoux et accessoires comptent parmi la panoplie de produits qui trouveront place dans tout décor. Fleuriste Bergeron, bien plus que des fleurs.</p>
                    <h3 class="f-primary mt-10">Fleurs coupées et arrangements naturels</h3>
                    <p class="mt-5">Toujours fraîches, nos fleurs proviennent de la Hollande, de l'équateur, de l'Italie, de la France et, bien sûr, du Canada. Indiquez-nous ce que vous recherchez ainsi que vos goûts et votre budget et nous vous créerons un arrangement unique.</p>
                    <h3 class="f-primary mt-10">Fleurs séchées et soie</h3>
                    <p class="mt-5">À partir de vos couleurs de peinture, de papier peint ou de tissus ainsi que de vos propres photos, nos spécialistes vous proposeront des conceptions au goût du jour adaptées à votre décor.</p>
                    <h3 class="f-primary mt-10">Plantes et jardins de plantes</h3>
                    <p class="mt-5">Que ce soit à la maison ou au travail, il est prouvé que les plantes améliorent l'air ambiant tout en créant une atmosphère chaleureuse. Choisissez parmi notre large éventail de plantes vertes, fleuries ou tropicales qui transformeront votre environnement et le rendra encore plus accueillant. De plus, nous pouvons effectuer leur entretien à votre lieu de travail. Informez-vous!</p>
                  </div >
                </v-col>
              </div>
            </v-row>
            <v-row class="grid-pad marginBtnProduct" style="">
              <v-col cols="12" md="3" class=""> 
                <v-btn to="/Boutique" target="_blank" class="btnStore2 v-secondary-light-5">Boutique</v-btn>
              </v-col>
              <v-col cols="12" md="9" class="">
                <h2 class="grid-pad storeTitle2" >Commandez en ligne !</h2>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
    -->

    <Footer class="mt-10"/>
  </div>
  
</template>
<style>

    .HomebtnStore {background-color: rgba(var(--secondary),0.5); width: auto; text-align: center; padding: 10px !important; margin-top: 80px !important; display: block;  float: right;}
    .HomebtnStore:hover { background-color: rgb(var(--secondary)) !important; }
    .HomebtnStore2 {background-color: rgba(var(--secondary),0.5); padding: 10px !important; margin-top: 80px !important; display: block;  float: left;}
    .HomebtnStore2:hover { background-color: rgb(var(--secondary)) !important; }
    .HomestoreTitle {font-family: Ephesis; font-size: 4em; padding-top: 50px !important; margin-left: 20px !important; text-align: left; color: rgb(255, 255, 255);  float: left;}
    .HomestoreTitle2 {font-family: Ephesis; font-size: 4em; padding-top: 50px !important; text-align: right; color: rgb(255, 255, 255);  float: right;}
    @media handheld, only screen and (max-width: 1300px) {
    .HomeFiftyContentSection{height: 1100px; width: 100%; background-image: url('../../public/Resource/fiftyyears.png'); background-position: bottom left; background-repeat: no-repeat; background-size: 35%; background-position-x: -7%;}
    .HomeFiftyYearsTitle{ font-family: Ephesis; text-align: right; font-size: 120px; line-height: 0.8; padding: 30px 30px 0px 0px!important; color: rgb(var(--secondary),0.3) !important;}
      /*second Store*/
    .HomesecondStore {position: absolute; display: block; z-index: 100; left: 40%; margin-top:-2% !important;}
    .HomesecondStoreText {text-transform: uppercase; font-weight: 200; padding-left: 100px !important;}
      /*second Founder*/
    .HomesecondFounder {position: absolute; display: block; z-index: 90; left: 65%; margin-top: 5% !important;}
    .HomesecondFounderText {text-transform: uppercase; font-weight: 200; padding-left: 120px !important;}
      /*first Founder*/
    .HomefirstFounder {position: absolute; display: block; z-index: 90; left: 15%; margin-top: -1% !important;}
    .HomefirstFounderText {text-transform: uppercase; font-weight: 200; padding-left: 0px !important;}
      /*first Store*/
    .HomefirstStore {position: absolute; display: block; z-index: 80; left: 28%; margin-top: 25% !important;}
    .HomefirstStoreText {text-transform: uppercase; font-weight: 200; padding-left: 0px !important;}
  }

</style>
<style scoped>
  /* service and product*/
  .grid-pad-home-a {padding: 0px 20px 0px 20px !important;}
  .grid-pad-home-b {padding: 0px 20px 0px 0px !important;}
  .grid-pad-home-d {padding: 0px !important;}
  .marginBtnModif {display: table; width: auto; margin-left:auto !important; margin-right:auto !important}
  .serviceBox {width: auto; height: 190px;}
  .productBox {width: auto; height: 190px;}
  #Services p {text-align: justify; font-size: 14px;}
  #Services h3 {text-align: left;}
  #Products p {text-align: justify; font-size: 14px;}
  #Products h3 {text-align: left;}
  .borderBox {height: 50%; width: 50%; border-right: 1px solid rgb(var(--secondary)) !important;}
  .borderBox2 {height: 50%; width: 50%; border-right: 1px solid rgb(var(--secondary)) !important;}
  /*.btnStore{ padding: 20px !important; margin-top: 115px !important;  margin-left: auto !important; margin-right: auto !important;}*/
  /*.btnStore:hover { background-color: rgb(var(--secondary)) !important;}*/
  /*.btnStore2{ padding: 10px !important; margin-top: 80px !important; display: block;  float: left;}*/
  /*.btnStore2:hover { background-color: rgb(var(--secondary)) !important;}*/
  /*.storeTitle {font-family: Ephesis; font-size: 4em; margin-top: 80px !important; color: rgb(255, 255, 255);}*/
  /*.storeTitle2 {font-family: Ephesis; font-size: 4em; padding-top: 50px !important; text-align: right; color: rgb(255, 255, 255);  float: right;}*/
  /*.productTitle {font-family: Ephesis; font-size: 4em; margin-top: 80px !important; text-align: right;}*/
  .servicesIMG {height: 100%; width: 100%; background-image: url('../../public/Resource/servicesIMG.jpg'); background-position: right center; background-size: cover; position:relative;}
  .productsIMG {height: 100%; width: 100%; background-image: url('../../public/Resource/productsIMG.jpg'); background-position: left center; background-size: cover; position:relative;}
  /*Photo Section*/
  .WaterMarkBackground { height: 800px; width: 100%; background-image: url('../../public/Resource/watercolorMark.jpg'); background-position: center center; background-size: cover; background-repeat: no-repeat;}
  .photographerIMG { top: 150px; height: 370px; width: auto; padding-top: 100px !important; display: block; margin: auto auto;}
  .photographerText {text-align: center; padding-top: 180px !important;}
  .weddingText {font-size: 2em; font-weight: 200;}
  .weddingText:hover {text-decoration: underline;}
  .dayText {font-size: 1.5em; font-weight: 200;}
  .dayText:hover {text-decoration: underline;}
  /*ConceptionBanner*/
  .ConceptionBanner { height: 500px; background-image: url('../../public/Resource/Banner/bannerDemonstration.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover;}
  .ConceptionColor { height: 500px; width: 100%; background-color: rgba(var(--secondary),0.5);}
  .conceptionText { color: rgb(var(--tierce)); font-size: calc(100px + 0.5vw); text-transform: uppercase; text-align: center; padding-top: 175px !important;}
  .conceptionText:hover {color: rgb(var(--secondary));}
    
    /* Fifty years Section */
  .HomeFiftyContentSection{height: 1100px; width: 100%; background-image: url('../../public/Resource/fiftyyears.png'); background-position: bottom left; background-repeat: no-repeat; background-size: 35%; background-position-x: -7%;}
  .HomeFiftyYearsTitle{ font-family: Ephesis; text-align: right; font-size: 180px; line-height: 0.8; padding: 30px 30px 0px 0px!important; color: rgb(var(--secondary),0.3) !important;}
    /*second Store*/
  .HomesecondStore {position: absolute; display: block; z-index: 100; left: 40%; margin-top:-2% !important;}
  .HomesecondStoreText {text-transform: uppercase; font-weight: 200; padding-left: 100px !important;}
  .HomesecondStoreIMG {height: 315px; width: 364px;}
    /*second Founder*/
  .HomesecondFounder {position: absolute; display: block; z-index: 90; left: 53%; margin-top: 5% !important;}
  .HomesecondFounderText {text-transform: uppercase; font-weight: 200; float: right;}
  .HomesecondFounderIMG {height: 345px; width: 345px;}
    /*first Founder*/
  .HomefirstFounder {position: absolute; display: block; z-index: 90; left: 25%; margin-top: -1% !important;}
  .HomefirstFounderText {text-transform: uppercase; font-weight: 200; padding-left: 0px !important;}
  .HomefirstFounderIMG {height: 345px; width: 345px;}
      /*first Store*/
  .HomefirstStore {position: absolute; display: block; z-index: 80; left: 28%; margin-top: 15% !important;}
  .HomefirstStoreText {text-transform: uppercase; font-weight: 200; padding-left: 0px !important;}
  .HomefirstStoreIMG {height: 420px; width: 566px;}
  /*.purpleBanner{height: 200px; width: 100%; margin-top:-200px !important; background-color: rgba(var(--primary),0.5) !important; z-index:0;}*/
  .purpleBanner{height: 200px; width: 100%; position:absolute; bottom:0; background-color: rgba(var(--primary),0.5); z-index:0;}

  .floatRight { float:right;}
  @media handheld, only screen and (max-width: 500px) {
  }

  @media handheld, only screen and (max-width: 600px) {
    #Products {height: auto;}
    .WaterMarkBackground { height: 600px; width: 100%; background-image: url('../../public/Resource/watercolorMark.jpg'); background-position: center center; background-size: cover; background-repeat: no-repeat;}
    .photographerIMG { top: 0px; height: 370px; width: auto; padding-top: 100px !important; display: block; margin: auto auto;}
    .photographerText {text-align: center; padding-top: 20px !important;}
    .HomestoreTitle {font-size: 2em !important;}
    .HomestoreTitle2 {font-size: 2em !important;}
  }
  @media handheld, only screen and (max-width: 959px) {
    .grid-pad-home-c {padding-left: 20px !important;}
    .conceptionText { color: rgb(var(--tierce)); font-size: calc(40px + 0.5vw); text-transform: uppercase; text-align: center; padding-top: 120px !important;}
    .ConceptionBanner { height: 300px; background-image: url('../../public/Resource/Banner/bannerDemonstration.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover;}
    .ConceptionColor { height: 300px; width: 100%; background-color: rgba(var(--secondary),0.5);}

    .service-pad {padding: 0px 20px !important;}
    .HomeFiftyContentSection{height: 1750px !important; width: 100% !important; background-image: none !important; background-position: bottom left; background-repeat: no-repeat; background-size: 35%; background-position-x: -7%;}
    .HomeFiftyYearsTitle{ font-family: Ephesis; text-align: right; font-size: 70px !important; line-height: 0.8; padding: 30px 30px 0px 0px!important; color: rgb(var(--secondary),0.3) !important;}
      /*second Store*/
    .HomesecondStore {position: relative !important; display: block !important; z-index: 100 !important; left: 0% !important;}
    .HomesecondStoreText {text-transform: uppercase !important; margin: 10px 0px !important; font-weight: 200; padding-left: 0px !important; padding-top: 10px !important;}
    .HomesecondStoreIMG {height: 315px !important; width: 100%;}
      /*second Founder*/
    .HomesecondFounder {position: relative !important; display: block !important; z-index: 90 !important; left: 0% !important; margin-top: 0% !important;}
    .HomesecondFounderText {text-transform: uppercase !important;  margin: 10px 0px !important; font-weight: 200; padding-left: 0px !important; float: left;}
    .HomesecondFounderIMG {height: 345px!important; width: 100%!important;}
      /*first Founder*/
    .HomefirstFounder {position: relative !important; display: block !important; z-index: 90 !important; left: 0% !important; margin-top: 0% !important;}
    .HomefirstFounderText {text-transform: uppercase;  margin: 10px 0px !important; font-weight: 200; padding-left: 0px !important;}
    .HomefirstFounderIMG {height: 345px !important; width: 100% !important;}
        /*first Store*/
    .HomefirstStore {position: relative !important; display: block !important; z-index: 80 !important; left:0% !important; margin-top: 0% !important;}
    .HomefirstStoreText {text-transform: uppercase;  margin: 10px 0px !important; font-weight: 200; padding-left: 0px !important;}
    .HomefirstStoreIMG {height: 420px !important; width: 100% !important;}
    /*.storeTitle {font-family: Ephesis; font-size: 2em; margin-top: 80px !important;}*/
    /*.btnStore{padding: 10px !important; margin-top: 90px !important; display: block; width: 100px;}*/
    /*.btnStoreLeftMobile{ padding: 10px !important; margin-top: 90px !important; display: block;}*/
    .HomestoreTitle {margin-top: -10px !important; text-align: center; float: none;}
    .HomestoreTitle2 {padding-top: 0px !important; text-align: center; float: none;}
    .HomebtnStore { margin-top: 0px !important;}
    .HomebtnStore2 { margin-top: 60px !important; margin-bottom: -60px !important;}
  }
  @media handheld, only screen and (max-width: 1279px) {
    .grid-pad-home-a {padding: 0px 20px !important;}
    .grid-pad-home-b {padding-right: 20px !important;}
  }

  @media handheld, only screen and (max-width: 1500px) {
    .HomeFiftyContentSection{height: 1100px; width: 100%; background-image: url('../../public/Resource/fiftyyears.png'); background-position: bottom left; background-repeat: no-repeat; background-size: 35%; background-position-x: -7%;}
    .HomeFiftyYearsTitle{ font-family: Ephesis; text-align: right; font-size: 120px; line-height: 0.8; padding: 30px 30px 0px 0px!important; color: rgb(var(--secondary),0.3) !important;}
      /*second Store*/
    .HomesecondStore {position: absolute; display: block; z-index: 100; left: 40%; margin-top:-2%;}
    .HomesecondStoreText {text-transform: uppercase; font-weight: 200; padding-left: 100px ;}
      /*second Founder*/
    .HomesecondFounder {position: absolute; display: block; z-index: 90; left: 65%; margin-top: 5% ;}
    .HomesecondFounderText {text-transform: uppercase; font-weight: 200; padding-left: 120px ;}
      /*first Founder*/
    .HomefirstFounder {position: absolute; display: block; z-index: 90; left: 15%; margin-top: -1% ;}
    .HomefirstFounderText {text-transform: uppercase; font-weight: 200; padding-left: 0px ;}
        /*first Store*/
    .HomefirstStore {position: absolute; display: block; z-index: 80; left: 28%; margin-top: 25%;}
    .HomefirstStoreText {text-transform: uppercase; font-weight: 200; padding-left: 0px ;}
    .HomestoreTitle {font-size: 3em; padding-top: 65px !important;}
    .HomestoreTitle2 {font-size: 3em; padding-top: 65px !important;}
  }

</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Diapo from '@/components/Diapo.vue'

export default {
  name: 'Home',
  data: () => ({

  }),
  mounted: async function () {

  },
  methods:{

  },
  components: {
    Header,
    Footer,
    Diapo,
  },
}
</script>
