<template>
    <div>
        <Header/>
        <div class="fixingTop"></div>
        <Banner/>

        <h1 class="h1Height">Boutique</h1>
        <h2 class="soustitre">Bienvenue dans notre boutique en ligne.</h2>
        <div class="grid grid-pad">
            <v-container>
                <v-row>
                    <v-col cols="6" >
                        <v-img  style="width: 15px; height: 15px; display: inline-block; padding-top: 10px !important; margin: 5px 10px 0px 10px !important;" src="../../public/Resource/icon/browse.png"/>
                        <h3 class="TitleCat " @click="changeBox()">Catégories</h3>
                        <h3 v-if ="this.selectedCategories != 0" class="TitleCat" style="margin-left:20px !important;" @click="navigateSamePage()">x</h3>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end">
                        <v-col cols="12" sm="8" md="8" lg="8">
                            <v-text-field id="onlineStore_SearchInput" name="onlineStore_SearchInput" v-model="searchItem" style="color: rgb(var(--primary)); font-weight: 700; " density="compact" variant="outlined" label="&nbsp;Recherche" append-inner-icon="mdi-magnify" @click:append-inner="search()" @keydown.enter="search()"></v-text-field>
                        </v-col>
                        
                    </v-col>
                </v-row>
                <div v-if="this.CategorieBox" class="rel BoxCat">
                    <div class="closeCategory"  @click="this.CategorieBox = false;">X</div>
                    <v-row >                       
                        <v-col class="TitleCatOpen"  v-for="(item, i) in categories" :key="i">
                            <v-container class="categorie" >   
                                <img style="display: inline-block; width:20px; height:20px; margin-right: 10px !important;"  :src="getLogoCategorie(item.id)" />                 
                                <h2 style="display: inline-block;" @click="navigateToCategory(item.id)">{{item.name}}</h2>
                            </v-container>
                        </v-col>
                    </v-row>
                </div>
                <h2 class="categorieName" v-if="CategorieName">{{ this.CategorieName }}</h2>
                <h2 class="soustitreCategorie" v-if="selectedCategories == 9">{{ this.CategorieSubtitle }}</h2>
            </v-container>
            <v-container class="container productContainer custom-scrollbar"> 
                <v-row v-if="this.Products" class="scrollbar">
                    <v-col class="" v-for="(item, i) in Products" :key="i">
                        <v-container @click="navigateTo(item)"  class="product"> <!--v-if="item.id != 3"  v-if="item.promo == false"-->
                            <div>
                                <div v-if="item.image.url"><v-img :ref="item.id.toString()" class="productImg" :src="item.image.url" :truesrc="item.image.url"/></div>
                                <div class="TextInfo">
                                    <div class="backgroundColor">
                                        <h2 class="productTitle">{{item.title}}</h2>
                                        <h2 class="productPrice"><span v-if="item.price != 0">{{item.price}}$</span><span v-else>Démonstrateur</span> <span class="productCode">{{item.accountingNumber}}</span></h2>
                                    </div>
                                </div>
                            </div>
                        </v-container>
                    </v-col>
                </v-row>
                <v-row v-if="this.Products.length == 0 && loading == false"><h2 class="soustitre">Auncun produit n'a été trouvé.</h2></v-row>
                <v-row v-if="loading == true">
                    <div style="width: 75%; display: block; margin-left: auto !important; margin-right: auto !important;">
                        <h2 class="soustitre">Les produits sont en chargement.</h2>
                        <v-progress-linear color="rgb(var(--primary))" indeterminate striped height="8"></v-progress-linear>
                    </div>
                </v-row>
            </v-container>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <partialListNavigation v-if="range" class="" v-model:response="response" @more="more"  />
                    </v-col>
                </v-row>
                
            </v-container>
        </div>     
        <Footer />
    </div>
</template>
<style>
    #onlineStore_SearchInput { padding-left: 8px !important ;}
    .productImg .v-img__img--contain { object-fit: scale-down; object-position: center bottom; }
    .v-pagination .v-btn {border-radius: 25% !important;}
    .onlineStore .v-pagination__item--is-active { background-color: rgba(var(--primary),0.5); color: white; border-radius: 25%;}
    .onlineStore .v-pagination__list { justify-content: right;}
</style>
<style scoped>
    .v-text-field__slot {height: auto;}
    .h1Height {height:180px;}
    .v-container {max-width: 100%;}
    .soustitreCategorie{font-size: 36px; font-family: Ephesis; text-align: center; color: rgba(000,000,000,0.5);}
    .container {display:block; margin-right: auto !important; margin-left: auto !important; margin-top: 50px !important;}
    .product {  height: 400px; width: 300px; position: relative; background-image: url('../../public/Resource/onlineStore/background.jpg'); background-position: center center; background-repeat: no-repeat; background-size: contain; display: block; margin: auto auto !important; cursor: pointer;}
    .productContainer {max-height: 750px; overflow-y: scroll;}
    .productContainer::-webkit-scrollbar { width: 1px !important; color: violet;}
    .PromoProduct { height: 500px; width: 100%; position: relative; display: block; margin: auto 0px !important; padding: 0px !important; border: 1px solid black;}
    .productImg {height: 100%; width: auto; min-width: 300px; position: absolute; background-color: white;}
    .product:hover .TextInfo{ visibility: visible;}
    .TextInfo{bottom: 0; visibility: hidden; position: absolute; z-index: 100; width: 100%;}
    .backgroundColor {background-color: rgba(var(--primary), 0.5);}
    .productTitle {color: white; padding: 20px 20px 0px 20px !important; width: 100%; display: block;}
    .productPrice {font-weight: 900; padding: 0px 20px 30px 20px !important; display: inline-block; color: rgb(var(--primary)) !important; text-shadow: 1px 0px 3px #FFFFFF;}
    .productCode {color: #000; font-size: 20px; font-weight: 700;}
    .fixingTop {height: 150px;}
    .TitleCat{color: rgb(var(--primary)); font-weight: 100; font-size: 24px; display: inline-block; cursor: pointer;}
    .TitleCatOpen{color: rgb(var(--primary)); font-weight: 100; font-size: 24px; display: inline-block; cursor: pointer;}
    .TitleCatOpen:hover{color: rgb(var(--secondary));}
    .TitleCat:hover {color: rgb(var(--secondary));}
    .BoxCat{height: auto; width: auto; min-width: 300px; max-width: 70%; position: absolute  ; z-index: 100; background-color: rgba(var(--secondary),0.8); padding: 25px !important;}
    .categorie { min-width: 250px; overflow: hidden; height: 50px; padding: 10px; }
    .categorie h2 {  font-size: 18px; font-weight: 100;}
    .categorie:hover { cursor: pointer; color: white; }
    .categorieName { color: rgb(var(--primary)); font-family: Ephesis; font-size: 72px; text-align: center;}
    .closeCategory {float: right; color: rgb(var(--primary)); position: absolute; right: 10px; top: 5px; cursor: pointer;}
    .closeCategory:hover {color: #FFFFFF;}
    .moreBtn {color: rgb(var(--primary));}
    .moreBtn:hover {background-color: rgb(var(--secondary)); color: #FFF;} 
    /*CSS pour scrollBar*/
        /* Styles pour la barre de défilement - Chrome, Edge et Safari */
    .custom-scrollbar { max-height: auto; overflow-y: scroll; scrollbar-width: thin; scrollbar-color: rgb(var(--primary)) rgba(var(--secondary),0.1); }
    .custom-scrollbar::-webkit-scrollbar-thumb { background-color: #888; border-radius: 5px; }
    .custom-scrollbar::-webkit-scrollbar-track { background-color: #f1f1f1; }
    /* Styles pour la barre de défilement - Firefox */
    @supports (scrollbar-color: auto) { .custom-scrollbar { scrollbar-color: rgb(var(--primary))  rgba(var(--secondary),0.1); scrollbar-width: thin; } }
    /* Styles pour la barre de défilement - Edge (Chromium) */
    @supports (-ms-overflow-style: none) { .custom-scrollbar { scrollbar-color: rgb(var(--primary)) rgba(var(--secondary),0.1); scrollbar-width: thin; } }

    @media handheld, only screen and (max-width: 600px) {
        .categorieName {font-size: 36px; }
        .soustitre { font-size: 48px; text-align: center; padding-left: 0px !important; padding-right: 2% !important;}
        .BoxCat{max-width: 100%;}
    }
    @media handheld, only screen and (max-width: 960px) {
        .fixingTop {height: 0px;}   
        .h1Height {height:auto;}
        .TextInfo{visibility:visible; z-index: 50;}
        .BoxCat{ background-color: rgba(var(--secondary),0.9);}
        .TitleCatOpen{color: rgb(var(--tierce));}
    }
    @media handheld, only screen and (max-width: 1264px) {
        
    }
    
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'
import partialListNavigation from '@/components/partialListNavigation.vue'

export default {
  name: 'Event',
  mixins: [apiECommerceExtension],
  data: () => ({
    Products: [],
    categories:[],
    selectedCategories: 0,
    searchBox: false,
    searchItem: "",
    CategorieBox: false,
    CategorieName: null,
    CategorieSubtitle: "Les produits mentionnés ci-dessous sont des exemples de démonstration et sont exclusivement disponibles en magasin.",
    loading: false,
    range: null,
    response: null,
  }),
  mounted: async function () {
    let id = this.$route.params.id;
    this.categories = await this.EcommerceCategoriesList();
    if(id){
        let found = false;
        this.categories.forEach(e => {
            if(e.id == id){ this.selectCategorie(e); found = true; }
        });
        if(found == false){this.navigateSamePage();}   
    }else{
        this.loading = true;
        this.Products = await this.EcommerceProductsList(this.range,this.callback);
        this.loading = false;
    }

    // Ajoutez cette ligne de code après avoir ajouté le contenu HTML à la zone de défilement
    document.querySelector('.custom-scrollbar').addEventListener('mouseover', function() {
    this.style.overflowY = 'auto';
    });

    document.querySelector('.custom-scrollbar').addEventListener('mouseout', function() {
    this.style.overflowY = 'scroll';
    });
  },
  computed: {

  },
  methods:{
    navigateSamePage: function(){ window.location = window.location.origin + '/Boutique' },
    navigateTo: function (item) {this.$router.push({ name: 'product', params: { id: item.id } });},
    navigateToCategory: function(id) { window.location = window.location.origin + '/Boutique/' + id },
    changeBox: function(){ if (!this.CategorieBox) {this.CategorieBox = true; }else{this.CategorieBox = false;} },
    changeSearchBox: function(){ if (!this.searchBox) {this.searchBox = true; }else{this.searchBox = false;} },
    selectCategorie: async function(item) {
        this.selectedCategories = item.id;
        this.loading = true;
        let filter = {"categoryId": item.id};
        this.Products = await this.EcommerceProductsListWithFilter(filter,this.range,this.callback);
        this.CategorieName = item.name;
        this.CategorieBox = false;
        this.loading = false;
    },
    getLogoCategorie: function(categoryId){
        let path;
        switch (categoryId) {
            case 4:
                path = '../Resource/icon/Bouquet.png';
                break;
            case 5:
                path = '../Resource/icon/arrangement.png';
                break;
            case 6:
                path = '../Resource/icon/plante.png';
                break;
            case 7:
                path = '../Resource/icon/panier.png';
                break;
            case 8:
                path = '../Resource/icon/sympathie.png';
                break;
            case 9:
                path = '../Resource/icon/mariage.png';
                break;
            case 10:
                path = '../Resource/icon/naissance.png';
                break;
            case 11:
                path = '../Resource/icon/noel.png';
                break;
            case 12:
                path = '../Resource/icon/st-valentin.png';
                break;
            case 13:
                path = '../Resource/icon/paques.png';
                break;
            case 14:
                path = '../Resource/icon/admin.png';
                break;
            case 15:
                path = '../Resource/icon/fetemere.png';
                break;       
            default:
                path = null;
                break;
        }

        return path;
    },
    ChangingImageSrc: function(){
        this.$nextTick(function () {
            if(this.Products){
                this.Products.forEach(e => {
                    var i = e.id.toString();
                    if(e.image.url){ 
                        this.$refs[i][0].$el.childNodes[2].src = this.$refs[i][0].$el.attributes['truesrc'].value;
                    }                   
                });
            }
        })
    },
    callback: function (response) {
        this.response = response;
        this.range = $App.getRangeHeader(response);
    },
    more: async function () {
        let id = this.$route.params.id;
        if(id){this.loading = true; let filter = {"categoryId": id}; this.Products = await this.EcommerceProductsListWithFilter(filter,this.range,this.callback);this.loading = false;} else {this.loading = true; this.Products = await this.EcommerceProductsList(this.range,this.callback); this.loading = false;}        
    },
    search: async function() {
        let filter = {"Keywords": this.searchItem};
        this.loading = true;
        this.Products = [];
        this.Products = await this.EcommerceProductsListWithFilter(filter,this.range,this.callback);
        if(this.Products) {this.loading = false;} 

    },
  },

  components: {
    Header,
    Footer,
    Banner,
    partialListNavigation
},
}
</script>